import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Speaker from "../components/sass-lp/speaker"

const Customers = () => (
  <Layout  nav={'hidden'}>
    <Seo title="Sophie D'Souza" />
    <Speaker />
  </Layout>
)

export default Customers
