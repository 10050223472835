import React, { useState, useEffect, useRef } from "react"

export default function AudioComp() {
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const isLoaded = useAudioLoaded(ref1);
    const isBarLoaded = useBarLoaded(ref2)
    const audioElement = isLoaded && document.querySelector(".react-audio-player ")
    const progressFilled = isBarLoaded && document.querySelector(".progress-line-playing");
    const [paused, setPause] = useState(false);
    const progressUpdate = () => {
        const percent = (audioElement.currentTime / audioElement.duration) * 100;
        progressFilled.style.width = `${percent}%`;
    }
    const progressBarUpdate = () => {
        audioElement?.addEventListener("timeupdate", () => {
            progressUpdate()
        });
    }
    function resetPlayer() {
        audioElement?.addEventListener("ended", () => {
            setPause(paused)
            progressFilled.style.width = "0%";
            audioElement.currentTime = 0;
        });
    }
    const handlePlayPause = () => {
        progressBarUpdate()
        audioElement?.play()
        if (paused) audioElement.pause()
        setPause(!paused)
        resetPlayer()
    }



    return (
        <div className="audio-wrap">
            <audio preload="auto" className="react-audio-player" ref={ref1}>
                <source src="https://res.cloudinary.com/spiralyze/video/upload/v1670939686/spiralyze2022/LP_Audio_3_1.mp3" type="audio/mpeg" />
            </audio>
            {isLoaded && <>
                <div className={paused === true ? 'play-pause-button playing' : 'play-pause-button'} onClick={() => handlePlayPause()}></div>
                <div className="progress-line-bg"></div></>}
                <div className="progress-line-playing" ref={ref2}></div>
        </div>
    )
}
function useAudioLoaded(ref) {
    const [isLoaded, setLoading] = useState(false);

    useEffect(() => {
        if (ref.current !== null) {
            setLoading(true)
        }
    }, []);

    return isLoaded;
}
function useBarLoaded(ref2) {
    const [isLoaded, setLoading] = useState(false);

    useEffect(() => {
        if (ref2.current !== null) {
            setLoading(true)
        }
    }, []);

    return isLoaded;
}