import * as React from "react"
import { StaticQuery, graphql } from "gatsby"
export default function Image({
  imgName,
  className,
  alt,
  fallBackImage,
  lazyLoad,
  sourceFolder,
  cloudinaryPath = "https://res.cloudinary.com/spiralyze/image/upload/f_auto/spiralyze2022",
  children,
}) {
  return (
    <StaticQuery
      query={graphql`
        {
          allFile(filter: { sourceInstanceName: { eq: "images" } }) {
            edges {
              node {
                extension
                dir
                name
                base
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      `}
      render={data => {
        if (data && imgName && typeof imgName === "string") {
          if (
            data.allFile.edges.find(element => element.node.base === imgName)
              ?.node.extension === "svg"
          ) {
            return (
              <img
                loading={lazyLoad === "false" ? "eager" : "lazy"}
                src={
                  cloudinaryPath.replace(/v+[0-9]{10}/, "") +
                  `/${sourceFolder}/${imgName}`
                }
                className={className}
                alt={alt}
              />
            )
          } else {
            let width = data.allFile.edges.find(element => {
              return element.node.base === imgName
            })?.node.childImageSharp.gatsbyImageData.width
            let height = data.allFile.edges.find(
              element => element.node.base === imgName
            )?.node.childImageSharp.gatsbyImageData.height
            width = width ? width + "px" : "auto"
            height = height ? height + "px" : "auto"
            return (
              <img
                width={width}
                height={height}
                loading={lazyLoad === "false" ? "eager" : "lazy"}
                style={{ height: "auto" }}
                src={
                  cloudinaryPath.replace(/v+[0-9]{10}/, `q_auto,f_auto`) +
                  `/${sourceFolder}/${imgName}`
                }
                className={className}
                alt={alt}
                srcSet={
                  cloudinaryPath.replace(/v+[0-9]{10}/, `q_auto,f_auto`) +
                  `/${sourceFolder}/${imgName}` +
                  " 2x" +
                  "," +
                  cloudinaryPath.replace(
                    /v+[0-9]{10}/,
                    `q_auto,f_auto,w_${Math.round(width / 2)},h_${Math.round(
                      height / 2
                    )}`
                  ) +
                  `/${sourceFolder}/${imgName}` +
                  " 1x"
                }
              />
            )
          }
        }
        if (imgName && typeof imgName === "object") {
          let defaultImageSrc =
            cloudinaryPath.replace(/v+[0-9]{10}/, `q_auto,f_auto`) +
            `/${sourceFolder}/${fallBackImage}`
          let defaultImageWidth = data.allFile.edges.find(
            element => element.node.base === fallBackImage
          )?.node.childImageSharp.gatsbyImageData.width
          let defaultImageHeight = data.allFile.edges.find(
            element => element.node.base === fallBackImage
          )?.node.childImageSharp.gatsbyImageData.height
          defaultImageWidth = defaultImageWidth ? defaultImageWidth : "1000"
          defaultImageHeight = defaultImageHeight ? defaultImageHeight : "1000"
          return (
            <picture>
              {imgName.map((item, index) => {
                let width = data.allFile.edges.find(
                  element => element.node.base === item.imageName
                ).node.childImageSharp.gatsbyImageData.width
                let height = data.allFile.edges.find(
                  element => element.node.base === item.imageName
                ).node.childImageSharp.gatsbyImageData.height
                let src =
                  cloudinaryPath.replace(/v+[0-9]{10}/, `q_auto,f_auto`) +
                  `/${sourceFolder}/${item.imageName}`
                return (
                  <source
                    key={index}
                    media={`(min-width:${item.minBreakpoint})`}
                    srcSet={
                      src +
                      " 2x" +
                      "," +
                      src.replace(
                        `q_auto,f_auto`,
                        `q_auto,f_auto,w_${Math.round(
                          width / 2
                        )},h_${Math.round(height / 2)}`
                      ) +
                      " 1x"
                    }
                  />
                )
              })}
              <img
                width={defaultImageWidth + "px"}
                height={defaultImageHeight + "px"}
                style={{ height: "auto" }}
                loading={lazyLoad === "false" ? "eager" : "lazy"}
                src={defaultImageSrc}
                className={className}
                alt={alt}
                srcSet={
                  defaultImageSrc +
                  " 2x" +
                  "," +
                  defaultImageSrc.replace(
                    `q_auto,f_auto`,
                    `q_auto,f_auto,w_${Math.round(
                      defaultImageWidth / 2
                    )},h_${Math.round(defaultImageHeight / 2)}`
                  ) +
                  " 1x"
                }
              />
            </picture>
          )
        }
      }}
    />
  )
}
