import React from "react"
import Image from "../image-component/image-new"
import Modal from "../modal/modal"
import YouTube from "react-youtube"
import "./lp.scss"

export default function VideoCarousel() {
    const hoverVideo = (e) => {
        document.querySelector('.video-wrap.active')?.classList.remove('active')
        e.target.closest('.video-wrap').classList.add('active')
    }

    return (
        <>
            <div className="video-wrap" onMouseOver={(e) => hoverVideo(e)}>
                <Modal
                    modalButton={
                        <div className={"sophie-video-preview"}>
                            <Image sourceFolder={'lp'}imgName={'Global_Summit_AB_Tasty_Sophie_D_Souza_Clip-min.jpg'} className={'img-preview'} />
                            <Image sourceFolder={'lp'}imgName={'play-button.svg'} className={'play-button'} />
                            <div className="preview-caption">Global Summit - <br/>AB Tasty</div>
                        </div>
                    }
                    modalContent={
                        <div className="iframe-wrap">
                            <YouTube videoId={'ia9Q93g5NIo'} />
                        </div>
                    }
                />
            </div>
            <div className="video-wrap active" onMouseOver={(e) => hoverVideo(e)}>
                <Modal
                    modalButton={
                        <div className={"sophie-video-preview"}>
                            <Image sourceFolder={'lp'}imgName={'Inventory_Management_and_Summit_Fishbowl_Live_Sophie_D_Souza_Clip-min.jpg'} className={'img-preview'} />
                            <Image sourceFolder={'lp'}imgName={'play-button.svg'} className={'play-button'} />
                            <div className="preview-caption">Inventory Management and Summit</div>
                        </div>
                    }
                    modalContent={
                        <div className="iframe-wrap">
                            <YouTube videoId={'WQt-9y7jGHg'} />
                        </div>
                    }
                />
            </div>
            <div className="video-wrap" onMouseOver={(e) => hoverVideo(e)}>
                <Modal
                    modalButton={
                        <div className={"sophie-video-preview"}>
                            <Image sourceFolder={'lp'}imgName={'LP_Video_Clip_2_-_Spiralyze-min.jpg'} className={'img-preview'} />
                            <Image sourceFolder={'lp'}imgName={'play-button.svg'} className={'play-button'} />
                            <div className="preview-caption">Partner Call - Answering Common Question</div>
                        </div>
                    }
                    modalContent={
                        <div className="iframe-wrap">
                            <YouTube videoId={'gkg3j3iUxBg'} />
                        </div>
                    }
                />
            </div>
        </>
    )
}
