import React, { useEffect, useState, useRef } from "react"
import { Link } from 'gatsby'
import Image from "../image-component/image-new"
import AudioComp from "./audio-comp"
import "./lp.scss"
import VideoCarousel from "./video-carousel"
import VideoSlider from "./video-slider"



const socialLogos = [
    {
        "imageName": "social-proof-desktop-n.png",
        "minBreakpoint": "992px"
    },
    {
        "imageName": "social-proof-tablet-n.png",
        "minBreakpoint": "768px"
    },
    {
        "imageName": "social-proof-mobile-n.png",
        "minBreakpoint": "320px"
    }
]
const sophiePic = [
    {
        "imageName": "IMG-Sophie-Desktop-min.png",
        "minBreakpoint": "768px"
    },
    {
        "imageName": "IMG-Sophie-Mobile-min.png",
        "minBreakpoint": "320px"
    }
]
function getWindowDimensions() {
    const isBrowser = () => typeof window !== "undefined"
    const { innerWidth: width, innerHeight: height } = typeof window !== "undefined" && window;
        return isBrowser() &&{
            width,
            height
        };
}
export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}
export default function LandingPage() {
    const ref1 = useRef(null);
    const isInViewport1 = useIsInViewport(ref1);
    const { height, width } = useWindowDimensions();
    useEffect(() => {
        document.querySelector('footer.footer').style.display = 'none'
    }, [])

    return (
        <span className="landing-page-wrap speaker">
            <div className="nav-logo-wrap">
                <div className="logo-section">
                    <Link to="/"><Image sourceFolder={'lp'} imgName={'SPZ-Logo-white.svg'} /></Link>
                </div>
            </div>
            <div className="hero-section">
                <div className="hero-sections-wrap">
                    <div className="hero-left">
                        <div className='hero-above-heading'>SAAS</div>
                        <div className='hero-heading'>Speaker: <br /><span className="yellow">Sophie Buonassisi</span></div>
                        <div className="extra-subheading">Vice President, Optimization at Spiralyze</div>
                        <div className='hero-subheading'>Delight your podcast audience and attract new listeners with topics around conversion rate optimization (CRO).</div>
                        <a href="https://calendly.com/meet_sophie_30min/" target="_blank" className="hero-cta">Book a Call</a>
                    </div>
                    <div className="hero-right">
                        <Image sourceFolder={'lp'} imgName={sophiePic} className="sophie-img" fallBackImage={'IMG-Sophie-Desktop-min.png'} alt={"Sophie D'Souza"} />
                        <div className="hero-phone">
                            <Image sourceFolder={'lp'} imgName={'Icon-Phone.svg'} alt={'Phone'}/>
                            <div>Phone:</div>
                            <a href="tel:778-385-6360">778-385-6360</a>
                        </div>
                        <div className="hero-email">
                            <Image sourceFolder={'lp'} imgName={'Icon-Letter.svg'} alt={'Email'}/>
                            <div>Email:</div>
                            <a href="mailto:sophied@spiralyze.com">sophied@spiralyze.com</a>
                        </div>
                        <AudioComp />
                    </div>
                </div>
                <div className="video-carousel-heading">Speaking Samples</div>
                <div className="carousel video-carousel" >
                    {width > 991 && <VideoCarousel />}
                    {width <= 991 && <VideoSlider />}
                </div>

                <div className="video-carousel-subheading">Sophie is the Vice President of Optimization at Spiralyze, a predictive conversion rate optimization service.</div>
            </div>
            <div className="social-proof-section">
                <div className="social-proof-heading">Spiralyze is Trusted by Top B2B SaaS Companies to Drive Results</div>
                <div className="social-logos-wrap">
                    <Image sourceFolder={'lp'} imgName={socialLogos} fallBackImage={'social-proof-desktop-n.png'} alt={'Company logos'}/>
                </div>
            </div>
            <div className="lp-footer " ref={ref1}>
                <Image sourceFolder={'lp'} imgName={'spz-bg-sign-8.svg'} className={isInViewport1 ? 'bg-footer animate' : 'bg-footer'} />
                <div className="lp-footer-wrap new-separator">
                    <div className="footer-left">Contact<br /> Sophie</div>
                    <div className="footer-right">
                        <div className="lp-footer-email">
                            <div>Email:</div>
                            <a href="mailto:sophied@spiralyze.com">sophied@spiralyze.com</a>
                        </div>
                        <div className="lp-footer-phone">
                            <div>Phone:</div>
                            <a href="tel:778-385-6360">778-385-6360</a>
                        </div>
                        <a href="https://calendly.com/meet_sophie_30min/" target="_blank" className="footer-cta">Book a Call</a>
                    </div>
                </div>
            </div>
        </span>
    )
}
function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);


    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) =>
            setIsIntersecting(entry.isIntersecting))
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, []);

    return isIntersecting;
}
